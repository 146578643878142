import PagesRoute from "./PagesRoute/PagesRoute";
import './App.css';
function App() {
  return (
    <div className="body">
      <PagesRoute />
    </div>
  );
}

export default App;
